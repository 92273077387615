import axios from "axios";

// Function to make a GET request to a given endpoint
export async function fetchData(url, params = {}) {
  try {
    // Make a GET request with optional query parameters
    const response = await axios.get(url, { params });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle errors
    console.error("Error fetching data:", error);
    if (error.response) {
      // The request was made, but the server responded with a status code outside of 2xx
      console.error("Response status:", error.response.status);
      console.error("Response data:", error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request
      console.error("Error message:", error.message);
    }
    throw error;
  }
}

// Function to make a POST request to a given endpoint
export async function postData(
  url,
  data = {},
  config = { headers: { "Content-Type": "application/json" } }
) {
  try {
    // Make a POST request with the provided data and optional configuration
    const response = await axios.post(url, data, config);

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle errors
    console.error("Error posting data:", error);
    if (error.response) {
      // The request was made, but the server responded with a status code outside of 2xx
      console.error("Response status:", error.response.status);
      console.error("Response data:", error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request
      console.error("Error message:", error.message);
    }
    throw error;
  }
}
