import styled from "styled-components";

const StyledBaseTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  .table-header-box {
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
  }

  .sync-button {
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 5px;
    border: 1px solid #f2f2f7;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;

    &:hover {
      background-color: #f0f0f5;
      border-color: #d9d9e3;
    }
  }
`;

export default StyledBaseTable;
