import { fetchData, postData } from "../utils/async";
import { EndpointRoutes, getEndpoint } from "../utils/globalConstants";
import { formatSize } from "../utils/helper";
import SyncStatus from "./syncStatus";
import User from "./user";

class Company {
  constructor() {}

  static async getCacheSyncStatus(env) {
    try {
      const response = await fetchData(
        getEndpoint(env, EndpointRoutes.GET_CACHE_SYNC_STATUS)
      );
      const status = SyncStatus.getStatus(response.data);
      return status;
    } catch (error) {
      console.error("failed to get cache sync status", error);
    }
  }

  static async addSyncToQueue(company, season, host, env) {
    try {
      const payload = JSON.stringify({
        company,
        season,
        host,
      });
      const response = await postData(
        getEndpoint(env, EndpointRoutes.ADD_SYNC_TO_QUEUE),
        payload
      );
      console.log("add sync to queue res", response);
    } catch (error) {
      console.error("failed to add sync to queue", error);
    }
  }

  static async getCompanyTree() {
    const envServers = await this.getAllCompanyDBList();
    console.log("all env server", envServers);
    const children = [];
    for (const envServer of envServers) {
      const { env } = envServer;
      const envChildren = this.getChildren(envServer.children, env);
      const envObj = {
        name: env,
        children: envChildren,
        metadata: { type: "env" },
      };
      children.push(envObj);
    }

    const tree = {
      name: "",
      children,
    };

    console.log("tree", tree);
    return tree;
  }

  static async getAllCompanyDBList() {
    const environments = ["PROD_NEW", "PROD_OLD", "DEV", "LOCAL"];

    try {
      const results = await Promise.all(
        environments.map(async (env) => {
          try {
            const response = await fetchData(
              getEndpoint(env, EndpointRoutes.GET_ALL_COMPANY_DBS)
            );
            const data = response.data;

            return {
              env,
              children: data,
            };
          } catch (error) {
            console.error("error getting env for ", env);
            return {
              env,
              children: [],
            };
          }
        })
      );

      return results;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  static getChildren(servers, env) {
    const children = [];

    for (const server of servers) {
      const serverName = server.server;
      const { companyDbs } = server;
      const companyChildren = companyDbs.map((comp) => ({
        name: comp,
        metadata: { type: "db", env, server: serverName },
      }));
      const obj = {
        name: serverName,
        metadata: { type: "server", env },
        children: companyChildren,
      };
      children.push(obj);
    }
    return children;
  }

  static async getCompanyCacheData(dbName, env) {
    try {
      const response = await fetchData(
        getEndpoint(env, `${EndpointRoutes.GET_CACHE_INFO}/${dbName}`)
      );
      const { data } = response;
      return data;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  static async getCompanyCacheTable(company) {
    const { name, metadata } = company;
    const { env } = metadata;
    const cacheData = await this.getCompanyCacheData(name, env);
    const tableData = cacheData.map((d) => ({
      id: d._id,
      length: d.length,
      size: d.chunkSize,
      sync_date: d.uploadDate,
      cache: d.filename,
    }));
    return tableData;
  }

  static async getCompanySeasonsWithCache(company) {
    const { name, metadata } = company;
    const { env } = metadata;
    const seasonsCache = await this.getCompanyCacheData(name, env);
    const tableData = seasonsCache.map((seasonData) => {
      const totalSize = seasonData.cache.reduce(
        (sum, item) => sum + item.length,
        0
      );
      const chunkSize = seasonData.cache.reduce(
        (sum, item) => sum + item.chunkSize,
        0
      );

      // Format the season data
      return {
        // data: {
        season: seasonData.season,
        chunkSize: formatSize(chunkSize),
        totalSize: formatSize(totalSize),
        syncDate: new Date(seasonData.cache[0].uploadDate).toLocaleString(), // Use first cache's date as sync date
        cache: "See children",
        // },
        children: seasonData.cache.map((cacheItem) => ({
          data: {
            name: cacheItem.filename,
            length: cacheItem.length,
            size: cacheItem.chunkSize,
            sync_date: new Date(cacheItem.uploadDate).toLocaleString(),
            cache: cacheItem._id,
          },
        })),
      };
    });
    return tableData;
  }

  static saveUserProfile(userProfile) {
    if (userProfile instanceof User) {
      const { env, companyDb } = userProfile;

      // Retrieve all profiles from localStorage, or initialize to an empty array if none exist
      let allProfiles = JSON.parse(
        localStorage.getItem("user_profiles") || "[]"
      );

      // Find or create the environment entry
      let envProfile = allProfiles.find((p) => p.env === env);
      if (!envProfile) {
        envProfile = { env: env, companies: [] };
        allProfiles.push(envProfile);
      }

      // Check if the company already exists in the environment
      let companyProfile = envProfile.companies.find(
        (company) => company.name === companyDb
      );

      if (!companyProfile) {
        // If no profiles for the specified company, create a new entry with profiles array
        companyProfile = { name: companyDb, profiles: [] };
        envProfile.companies.push(companyProfile);
      }

      // Check if the user profile already exists in the company's profiles array
      const existingProfileIndex = companyProfile.profiles.findIndex(
        (profile) => profile.id === userProfile.id
      );

      if (existingProfileIndex !== -1) {
        // Update the existing profile
        companyProfile.profiles[existingProfileIndex] = userProfile;
      } else {
        // Add the new profile to the beginning of the profiles array
        companyProfile.profiles.unshift(userProfile);
      }

      // Save updated profiles back to localStorage
      localStorage.setItem("user_profiles", JSON.stringify(allProfiles));

      return this.getCompanyCustomProfiles(companyDb, env);
    }

    return false;
  }

  static getCompanyCustomProfiles(company, env) {
    // Retrieve and parse all profiles from localStorage
    const allProfiles = JSON.parse(
      localStorage.getItem("user_profiles") || "[]"
    );
    if (allProfiles.length < 1) return [];

    // Find the environment profiles
    const envProfiles = allProfiles.find((p) => p.env === env);
    if (!envProfiles || !envProfiles.companies) return [];

    // Find the specific company profile within the environment
    const companyProfile = envProfiles.companies.find(
      (comp) => comp.name === company
    );
    if (!companyProfile || !companyProfile.profiles) return [];

    // Map the profiles to User instances
    const userInstances = companyProfile.profiles.map(
      (profile) => new User(profile)
    );
    return userInstances;
  }
}

export default Company;
