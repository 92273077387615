import styled from "styled-components";

const StyledCacheCard = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  cursor: default;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  .companies-list {
    width: 300px;
    height: 100%;
    background: #f2f2f7;
    border-radius: 5px;
    flex-shrink: 0;
  }

  .cache-data-table-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    gap: 10px;
  }

  .company-sync-status-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  .table-header {
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: #f2f2f7;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .table-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    border: 1px solid #f2f2f7;
    padding: 10px;
  }
  
`;

export default StyledCacheCard;
