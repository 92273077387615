import React, { useRef } from "react";
import Table from "react-table-lite";
import IconButton from "../IconButton";
import StyledBaseTable from "./style";
import ResetIcon from "../../assets/icons/ResetIcon";
// import { Download } from 'react-feather';

function BaseTable({ data, company, addToQueue }) {
  /*Create a ref for custom download button*/
  const customDownloadButtonRef = useRef(null);

  return (
    <StyledBaseTable>
      <div className="table-header-box">
        <span>{company}</span>
        <button onClick={() => addToQueue("all")} className="sync-button">
          <IconButton icon={<ResetIcon />} /> Sync All
        </button>
      </div>
      <Table
        data={data}
        headers={["season", "chunkSize", "totalSize", "syncDate"]}
        actionTypes={["edit"]}
        showActions={true}
        downloadable={false}
        csvKeys={["season", "chunkSize", "totalSize", "syncDate"]}
        downloadCsvButtonRef={customDownloadButtonRef}
        fileName={"Table_Data"}
        customHeaders={{
          season: "Season",
          chunkSize: "Chunk Size",
          totalSize: "Total Size",
          syncDate: "Last Synced",
        }}
        // actionTypes={["sync"]}
        customRenderActions={{
          edit: (row) => (
            <button
              onClick={() => addToQueue(row.season)}
              className="sync-button"
            >
              <IconButton icon={<ResetIcon />} /> Sync
            </button>
          ),
        }}
      />
    </StyledBaseTable>
  );
}

export default BaseTable;
