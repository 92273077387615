import React from "react";
import styled from "styled-components";

const StyledChip = styled.div`
  padding: 5px 15px;
  background-color: ${(props) => props.background || "#fff"};
  color: ${(props) => props.color || "#000"};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const StatusChip = ({ background, color, children }) => {
  return (
    <StyledChip color={color} background={background}>
      {children}
    </StyledChip>
  );
};

export default StatusChip;
