import React from "react";

const ResetIcon = ({ width = "15", height = "18", color = "#636366" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 119 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.4423 98.634C81.0423 107.034 67.9423 111.034 54.6423 109.734C44.1423 108.734 34.3423 104.634 26.4423 97.934H39.3423V89.934H13.3423V115.934H21.3423V104.034C30.4423 111.734 41.7423 116.534 53.9423 117.634C55.8423 117.834 57.6423 117.934 59.4423 117.934C72.9423 117.934 85.9423 113.334 96.4423 104.734C115.542 89.334 123.042 64.234 115.542 40.834L107.942 43.234C114.342 63.634 107.942 85.334 91.4423 98.634Z"
        fill={color}
      />
      <path
        d="M98.3423 14.7337C77.1423 -3.96627 44.8423 -5.26626 22.2423 13.1337C3.24233 28.5337 -4.25767 53.4337 3.04233 76.7337L10.6423 74.3337C4.34233 54.2337 10.8423 32.6337 27.2423 19.4337C46.9423 3.43373 75.0423 4.63373 93.3423 21.0337H80.3423V29.0337H106.342V3.03373H98.3423V14.7337Z"
        fill={color}
      />
    </svg>
  );
};

export default ResetIcon;
