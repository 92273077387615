import React from "react";
import TreeView, { flattenTree } from "react-accessible-treeview";
import DBIcon from "../../assets/icons/DBIcon";
import StyledCustomTreeView from "./style.js";
import GlobeIcon from "../../assets/icons/GlobeIcon.jsx";
import ServerIcon from "../../assets/icons/ServerIcon.jsx";

const folder = {
  name: "",
  children: [
    {
      name: "src",
      children: [{ name: "index.js" }, { name: "styles.css" }],
    },
    {
      name: "node_modules",
      children: [
        {
          name: "react-accessible-treeview",
          children: [{ name: "index.js" }],
        },
        { name: "react", children: [{ name: "index.js" }] },
      ],
    },
    {
      name: ".npmignore",
    },
    {
      name: "package.json",
    },
    {
      name: "webpack.config.js",
    },
  ],
};

// const dummyData = flattenTree(folder);

const CustomTreeView = ({
  data = folder,
  onSelectEnv = () => {},
  onSelectServer = () => {},
  onSelectDb = () => {},
  ...props
}) => {
  const treeData = flattenTree(data);

  return (
    <StyledCustomTreeView {...props}>
      <div className="ide">
        <TreeView
          data={treeData}
          aria-label="directory tree"
          togglableSelect
          clickAction="EXCLUSIVE_SELECT"
          multiSelect
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            getNodeProps,
            level,
            handleSelect,
            handleExpand,
          }) => (
            <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
              <div
                onClick={() => {
                  switch (level) {
                    case 1:
                      onSelectEnv(element);
                      break;
                    case 2:
                      onSelectServer(element);
                      break;
                    case 3:
                      onSelectDb(element);
                      break;
                    default:
                      break;
                  }
                }}
              >
                <Icon level={level} />
                {element.name}
              </div>
            </div>
          )}
        />
      </div>
    </StyledCustomTreeView>
  );
};

const Icon = ({ level }) => {
  switch (level) {
    case 1:
      return <GlobeIcon className="icon" />;
    case 2:
      return <ServerIcon className="icon" />;
    case 3:
      return <DBIcon className="icon" />;
    default:
      return null;
  }
};

export default CustomTreeView;
