export function CreateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const CreateUUIDWithoutDash = () => CreateUUID().replace(/-/g, "");


export function formatSize(bytes) {
  if (bytes >= 1024 * 1024) {
    // Convert to MB if size is >= 1 MB
    return `${(bytes / (1024 * 1024)).toFixed(3)} MB`;
  } else {
    // Convert to KB otherwise
    return `${(bytes / 1024).toFixed(2)} KB`;
  }
}