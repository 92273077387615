// export const baseUrl = {
//   DEV: "http://localhost:3080",
//   PROD_NEW: "http://localhost:3080",
//   PROD_OLD: "http://localhost:3080",
//   LOCAL: "http://localhost:3080",
// };

export const baseUrl = {
  DEV: "https://dev.api.proflowtree.com",
  PROD_NEW: "https://new-api.proflowtree.com",
  PROD_OLD: "https://api.proflowtree.com",
};

export const clientUrl = {
  DEV: "https://dev.proflowtree.com",
  PROD_NEW: "https://new.proflowtree.com",
  PROD_OLD: "https://www.proflowtree.com",
  LOCAL: "http://localhost:3000",
};

export const wsUrl = {
  DEV: "ws://13.114.53.90:4001",
};

export const EndpointRoutes = {
  GET_ALL_COMPANY_DBS: "/api/admin/company",
  GET_CACHE_INFO: "/api/admin/cache/company",
  GET_USER_PROFILES: "/api/admin/profile",
  GET_CACHE_SYNC_STATUS: "/api/admin/cache/status",
  ADD_SYNC_TO_QUEUE: "/api/admin/cache/queue",
};

export const getEndpoint = (env, route) => {
  return baseUrl[env] + route;
};
