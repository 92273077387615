import React, { useEffect, useState } from "react";
import StyledCacheCard from "./style";
import CustomTreeView from "../CustomTreeView";
import Company from "../../controllers/company";
// import BaseTable from "../BaseTable";
// import BaseTreeTable from "../BaseTreeTable";
// import IconButton from "../IconButton";
// import ResetIcon from "../../assets/icons/ResetIcon";
import BaseTable from "../BaseTreeTable/tableLite";
import StatusChip from "../StatusChip";
import useCacheSync from "../../hooks/useCacheSync";
import SyncLoader from "../../assets/icons/SynchingLoader";

const CacheConfigCard = () => {
  const [treeData, setTreeData] = useState({ name: "", children: [] });
  const [cacheData, setCacheData] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedEnv, setSelectedEnv] = useState(null);
  const [selectedServer, setSelectedServer] = useState(null);

  const [syncStatus, addToQueue] = useCacheSync({
    env: selectedEnv,
    server: selectedServer,
  });

  const getCompanyTree = async () => {
    const tree = await Company.getCompanyTree();
    if (!tree) return;
    const { children } = tree;
    const onlyDev = children.filter((env) => env.name === "DEV");
    console.log("only dev tree", onlyDev);
    setTreeData({ children: onlyDev, name: "" });
  };

  useEffect(() => {
    getCompanyTree();
  }, []);

  const fetchCacheData = async (db) => {
    const company = db || {
      name: selectedCompany,
      metadata: { env: selectedEnv },
    };
    if (!company.name) return;
    const tableData = await Company.getCompanySeasonsWithCache(company);
    setCacheData(tableData);
  };

  const handleSelectDb = async (db) => {
    console.log("db", db);
    await fetchCacheData(db);
    setSelectedCompany(db.name);
  };

  const handleSelectEnv = (env) => {
    if (!env) return;
    setSelectedEnv(env.name);
  };

  useEffect(() => {
    fetchCacheData();
  }, [syncStatus]);

  const handleSelectServer = (server) => {
    setSelectedServer(server.name);
  };

  const handleAddToQueue = (season) => {
    const queue = Company.addSyncToQueue(
      selectedCompany,
      season,
      selectedServer,
      selectedEnv
    );
  };

  return (
    <StyledCacheCard>
      <div className="companies-list">
        <CustomTreeView
          data={treeData}
          onSelectDb={handleSelectDb}
          onSelectEnv={handleSelectEnv}
          onSelectServer={handleSelectServer}
        />
      </div>
      <div className="cache-data-table-wrapper">
        {syncStatus && (
          <div className="table-header">
            {syncStatus.status ? (
              <>
                <StatusChip background="#34C759" color="#fff">
                  SYNC: ON
                </StatusChip>
                <StatusChip>
                  <SyncLoader />
                  In progress: {`${syncStatus.company}-${syncStatus.season}`}
                </StatusChip>
              </>
            ) : (
              <StatusChip background="#FF3B30" color="#fff">
                SYNC: OFF
              </StatusChip>
            )}
          </div>
        )}

        {/* <div className="company-sync-status-container">
          <div className="companyName">{selectedCompany}</div>
          <IconButton icon={<ResetIcon />} />
        </div> */}

        <div className="table-container">
          {/* {cacheData && <BaseTable data={cacheData} />} */}
          {/* {cacheData && <BaseTreeTable data={cacheData} />} */}
          <BaseTable
            data={cacheData}
            company={selectedCompany}
            addToQueue={handleAddToQueue}
          />
        </div>
      </div>
    </StyledCacheCard>
  );
};

export default CacheConfigCard;
