class SyncStatus {
  constructor() {
    this.sync = null;
    this.company = null;
    this.season = null;
    this.status = null;
  }

  static getStatus(syncStatus) {
    const newStatus = new SyncStatus();
    const { sync, company, season, status } = syncStatus;
    if (!sync && company === "all") {
      newStatus.sync = false;
    } else {
      newStatus.sync = true;
      newStatus.company = company;
      newStatus.season = season;
      newStatus.status = status;
    }

    return newStatus;
  }
}

export default SyncStatus;
