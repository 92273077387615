import { useCallback, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import Company from "../controllers/company";
import SyncStatus from "../controllers/syncStatus";
import { wsUrl } from "../utils/globalConstants";

const useCacheSync = ({ env, server }) => {
  const [syncStatus, setSyncStatus] = useState();
  const { sendMessage, lastMessage, readyState } = useWebSocket(wsUrl.DEV);

  const fetchSyncStatus = useCallback(async () => {
    console.log("env", env);
    if (!env) return;
    const status = await Company.getCacheSyncStatus(env);
    console.log("sync status", status);
    setSyncStatus(status);
  }, [env]);

  const updateStatus = useCallback(() => {
    if (!lastMessage) return;
    console.log("new message", lastMessage);
    const parsedMessage = JSON.parse(lastMessage.data);
    const { data, message } = parsedMessage;
    if (!data) return;
    const status = SyncStatus.getStatus(data);
    setSyncStatus(status);
  }, [lastMessage]);

  useEffect(() => {
    fetchSyncStatus();
  }, [env]);

  useEffect(() => {
    updateStatus();
  }, [lastMessage]);

  return [syncStatus];
};

export default useCacheSync;
